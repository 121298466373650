<template>
  <div id="app">
    We moved to <a href="https://rplay.live">RPlay</a>. See you there!
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    let entryPoint = window.location.href
    console.log(entryPoint.substring(0, 8))
    if(entryPoint.substring(0, 8) === "https://") {
      const url = entryPoint.slice(8)
      const rplayUrl = "https://rplay.live" + "/" + url.split("/").slice(1).join("/")
      window.location = rplayUrl
    } else {
      window.location = "https://rplay.live"
    }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
